<template>
  <div class="result">
    <div class="result-content">

            <span class="wechat">
                <i class="iconfont icon-wechat"></i>
            </span>
      <span class='result-text'>微信支付</span>
      <span class="result-text">
        支付遇到问题? 马上联系小助手
      </span>
      <div style="text-align: center;margin-top: 16px">
        <img style="width: 80%;" src="https://www.001ppt.com/redirect?id=wx_xzs" alt="">
      </div>

    </div>
    <div class="back-btn">
      <a href="javascript: void (0)" @click="success">我已支付</a>
      <a class="not-pay" href="javascript:void (0)" @click="goRedirect">暂未支付</a>
    </div>
  </div>
</template>

<script>
import {user} from '@/api'

export default {
  name: "index",
  data() {
    return {
      ifPay: false,
      order: ''
    }
  },
  created() {
    this.order = this.$route.params.order;
  },

  methods: {

    goRedirect() {
      const idx = window.location.href.indexOf('inner_redirect');

      const redirect = window.location.href.slice(idx + 'inner_redirect='.length);
      window.location.href = decodeURIComponent(redirect)
    },

    success() {
      if (window.location.href.indexOf('inner_redirect') !== -1) {
        return this.goRedirect();
      }
      this.getWechatOrderStatus();
    },


    async getWechatOrderStatus() {
      // const {code, data, msg} = await user.getH5Order(this.order);
      // if(data){
      setTimeout(_ => {
        this.$router.push('/record?tab=RECHARGE');
      }, 1500);
      // }else {
      //     this.$toast.error('支付失败');
      //
      //     setTimeout(_ => {
      //         this.$router.go(-2);
      //     },1500);
      // }


    }
  }
}
</script>

<style scoped lang="less">
.result {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .result-content {
    flex: 1;
    box-sizing: border-box;
    padding: 80px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .alipay {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      background: var(--zhifubao);
      border-radius: 100%;

      i {
        font-size: 40px;
        margin-left: 8px;
        color: #fff;
      }

    }

    .wechat {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      i {
        font-size: 65px;
        color: var(--weixin);
      }
    }

    .result-text {
      display: block;
      margin-top: 4px;
      font-size: 16px;
    }

    .result-price {
      font-size: 24px;
      font-weight: bold;
      margin-top: 20px;
    }
  }

  .back-btn {
    height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 270px;
      border-radius: 22px;
      background: var(--text-color);
      color: #fff;
      font-size: 16px;
      margin-bottom: 12px;
    }

    .not-pay {
      background: #eeeeee;
      color: #151d36;
    }
  }
}
</style>
